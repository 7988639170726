<template>
  <IonApp>
    <IonSplitPane content-id="main-content">
      <ion-menu content-id="main-content" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Sellebrity</ion-list-header>
            <ion-note v-if="userIsLoggedIn">Angemeldet als {{ userName }} <ion-icon v-if="userIsStar" :ios="starOutline" :md="starSharp"></ion-icon> </ion-note>
  
            <ion-menu-toggle auto-hide="false" v-for="(p, i) in appPages" :key="i">
              <ion-item @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
  
          <ion-list id="labels-list" v-if="categories">
            <ion-list-header>Kategorien</ion-list-header>

            <ion-menu-toggle auto-hide="false" v-for="(category) in categories" :key="category.uuid">
              <a :href="$router.resolve(category.url).href">
                <ion-item @click="showCategory(category)" lines="none" class="hydrated">
                  <ion-icon slot="start" :ios="bookmarkOutline" :md="bookmarkSharp"></ion-icon>
                  <ion-label>{{ category.name }}</ion-label><small>{{ Object.keys(category.inserats).length }} Inserate</small>
                </ion-item>
              </a>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script lang="ts">
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { ticketOutline, ticketSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, albumsOutline, albumsSharp, handLeftOutline, handLeftSharp, starOutline, starSharp, personCircleOutline, personCircleSharp, logInOutline, logInSharp, addCircleOutline, addCircleSharp } from 'ionicons/icons';
import moment from 'moment';
import axios from 'axios';
import NotificationsModal from './views/notifications.vue';


export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  data () {
    return {
      authIsLoading: false,
      categories: null,
      totalCategories: 0,
    }
  },
  methods: {
      async openNotificationsModal() {
        const modal = await modalController
            .create({
              component: NotificationsModal,
            })
        return modal.present();
      },
      showCategory(category){
          console.log("Category: " + category.uuid + " selected.");
      },
      renewAuth() {

        if (localStorage.getItem('refreshToken') !== null){
            console.log("Renewing Auth");
            // renew token
            axios.post(process.env.VUE_APP_API_URL + '/token/refresh', {
              'refresh_token': localStorage.refreshToken
            })
          .then(result => {
                localStorage.refreshToken = result.data.refresh_token;
                localStorage.userToken = result.data.token;
          });
        }

      },
      logInCheck() {
        // check if user is logged in or not
        if (localStorage.userId && localStorage.userName && localStorage.userToken){

          this.renewAuth();

          this.userIsLoggedIn = true;
          this.userName = localStorage.userName;

          this.appPages.push(
              {
                title: 'Deine Tickets',
                url: '/profile/entries',
                iosIcon: ticketOutline,
                mdIcon: ticketSharp
              }
          );

          this.appPages.push(
              {
                title: 'Profil',
                url: '/profile',
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp
              }
          );

          this.appPages.push(
              {
                title: 'Ausloggen',
                url: '/logout',
                iosIcon: logInOutline,
                mdIcon: logInSharp
              }
          );

          if (!localStorage.userRoles.includes('ROLE_STAR')){
            this.appPages.push(
                {
                  title: 'Star Bewerbung',
                  url: '/star-application',
                  iosIcon: archiveOutline,
                  mdIcon: archiveSharp
                }
            );
          } else {
            this.userIsStar = true;

            this.appPages.unshift(
                {
                  title: 'Meine Inserate',
                  url: '/profile/inserats',
                  iosIcon: albumsOutline,
                  mdIcon: albumsSharp,
                }
            );

            this.appPages.unshift(
                {
                  title: 'Inserat erstellen',
                  url: '/inserat/create',
                  iosIcon: addCircleOutline,
                  mdIcon: addCircleSharp,
                }
            );


          }


        } else {
          this.userIsLoggedIn = false;
          this.appPages.push(
              {
                title: 'Einloggen',
                url: '/login',
                iosIcon: logInOutline,
                mdIcon: logInSharp
              }
          );
        }

        if ((localStorage.refreshDone === "true") && (localStorage.redirectedToAllInserats === "false")){
          // if user is logged in and refresh is done, push to all inserats
          localStorage.redirectedToAllInserats = true;

          // if user needs confirmation, route user to confirm site
          if (localStorage.userIsVerified === "false"){
            this.$router.push({ path: '/register/confirm'});
          } else {
            this.$router.push({ path: '/all'});
          }
        }
      }
  },
  mounted() {

    // check if user is logged in or not
    this.logInCheck();

    // get categories
    axios.get(process.env.VUE_APP_API_URL + '/categories').then(response => {
      this.categories = response.data["hydra:member"];
      this.totalCategories = response.data["hydra:totalItems"];

      this.categories.forEach(category => {
          category.url = "/all/category/" + category.uuid;
      });
    });


    if (localStorage.userId && localStorage.userToken){
      // check if user has new notifications
      console.log("Checking User Notifications");
      axios.get(process.env.VUE_APP_API_URL + '/check-user-notifications', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      }).then(response => {
        if (response.data.status == true){
          this.openNotificationsModal();
        }
      });
    }

    window.addEventListener('user-state-changed', (event) => {

      // check if user is logged in or not
      if (localStorage.userId && localStorage.userName && localStorage.userToken ){

        this.userIsLoggedIn = true;
        this.userName = localStorage.userName;

        // force refresh
        if (!localStorage.refreshDone){
            localStorage.refreshDone = true;
            window.location.reload();
        }

      } else {
        console.log("No User Data Found.");
        this.userIsLoggedIn = false;
        this.userName = null;
        this.$router.go(0);
      }

    });


  },
  setup() {
    const selectedIndex = ref(2);
    const appPages = [
      {
        title: 'Alle Inserate',
        url: '/all',
        iosIcon: albumsOutline,
        mdIcon: albumsSharp
      },
      {
        title: 'Für den Guten Zweck',
        url: '/goodCause',
        iosIcon: handLeftOutline,
        mdIcon: handLeftSharp
      },
      {
        title: 'Stars',
        url: '/stars',
        iosIcon: starOutline,
        mdIcon: starSharp
      },
      {
        title: 'Zuletzt Verkauft',
        url: '/all/last-buyed',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders', 'Notes', 'Work', 'Travel', 'Reminders'];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const userIsLoggedIn = false;
    const userName = null;
    const userIsStar = false;

    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages,
      labels,
      userIsLoggedIn,
      userName,
      userIsStar,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline,
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      albumsOutline,
      albumsSharp,
      handLeftOutline,
      handLeftSharp,
      starOutline,
      starSharp,
      personCircleOutline,
      personCircleSharp,
      logInOutline,
      logInSharp,
      addCircleOutline,
      addCircleSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
</script>

<style scoped>

a {
  text-decoration: none;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

</style>