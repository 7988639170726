import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import ('../views/auth/sessionCheck.vue')
  },
  {
    path: '/test-take-photo',
    component: () => import ('../views/inserat/pickImage.vue')
  },
  {
    path: '/login',
    component: () => import ('../views/auth/login.vue')
  },
  {
    path: '/register',
    component: () => import ('../views/auth/register.vue')
  },
  {
    path: '/register/confirm',
    component: () => import ('../views/auth/confirm.vue')
  },
  {
    path: '/register/confirm/success',
    component: () => import ('../views/auth/confirmation.vue')
  },
  {
    path: '/logout',
    component: () => import ('../views/auth/logout.vue')
  },
  {
    path: '/folder/:id',
    component: () => import ('../views/Folder.vue')
  },
  {
    path: '/all',
    component: () => import ('../views/inserat/showAll.vue')
  },
  {
    path: '/all/last-buyed',
    component: () => import ('../views/inserat/lastBuyed.vue')
  },
  {
    path: '/all/category/:id',
    component: () => import ('../views/inserat/categoryShowAll.vue')
  },
  {
    path: '/buy',
    component: () => import ('../views/inserat/buy.vue')
  },
  {
    path: '/buy-success',
    component: () => import ('../views/inserat/buy-success.vue')
  },
  {
    path: '/buy-canceled',
    component: () => import ('../views/inserat/buy-canceled.vue')
  },
  {
    path: '/inserat/create',
    component: () => import ('../views/inserat/create.vue'),
  },
  {
    path: '/inserat/create/step-1',
    component: () => import ('../views/inserat/createSteps/step1.vue')
  },
  {
    path: '/inserat/create/step-2',
    component: () => import ('../views/inserat/createSteps/step2.vue')
  },
  {
    path: '/inserat/create/step-3',
    component: () => import ('../views/inserat/createSteps/step3.vue')
  },
  {
    path: '/inserat/create/step-4',
    component: () => import ('../views/inserat/createSteps/step4.vue')
  },
  {
    path: '/inserat/create/step-5',
    component: () => import ('../views/inserat/createSteps/step5.vue')
  },
  {
    path: '/inserat/create/step-6',
    component: () => import ('../views/inserat/createSteps/step6.vue')
  },
  {
    path: '/inserat/:id',
    component: () => import ('../views/inserat/show.vue')
  },
  {
    path: '/inserat/:id/edit',
    component: () => import ('../views/inserat/editInserat.vue')
  },
  {
    path: '/inserat/:id/edit/add-image',
    component: () => import ('../views/inserat/addImage.vue')
  },
  {
    path: '/inserat/:id/edit/edit-data',
    component: () => import ('../views/inserat/editData.vue')
  },
  {
    path: '/inserat/:id/edit/activate-automatic-renewal',
    component: () => import ('../views/inserat/activateAutomaticRenewal.vue')
  },
  {
    path: '/goodCause',
    component: () => import ('../views/inserat/goodCause.vue')
  },
  {
    path: '/stars',
    component: () => import ('../views/Stars.vue')
  },
  {
    path: '/star/:id',
    component: () => import ('../views/showStar.vue')
  },
  {
    path: '/star-application',
    component: () => import ('../views/StarApplication.vue')
  },
  {
    path: '/profile',
    component: () => import ('../views/auth/profile.vue')
  },
  {
    path: '/profile/entries',
    component: () => import ('../views/auth/raffleEntries.vue')
  },
  {
    path: '/profile/inserats',
    component: () => import ('../views/auth/userInserats.vue')
  },
  {
    path: '/test',
    component: () => import ('../views/Test.vue')
  },
  {
    path: '/check-payment/:id',
    component: () => import ('../views/payment/checkPayment.vue')
  },
  {
    path: '/make-payment/:id',
    component: () => import ('../views/payment/createCheckoutSession.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
