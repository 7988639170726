
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { ticketOutline, ticketSharp, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, albumsOutline, albumsSharp, handLeftOutline, handLeftSharp, starOutline, starSharp, personCircleOutline, personCircleSharp, logInOutline, logInSharp, addCircleOutline, addCircleSharp } from 'ionicons/icons';
import moment from 'moment';
import axios from 'axios';
import NotificationsModal from './views/notifications.vue';


export default defineComponent({
  name: 'App',
  components: {
    IonApp, 
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
  },
  data () {
    return {
      authIsLoading: false,
      categories: null,
      totalCategories: 0,
    }
  },
  methods: {
      async openNotificationsModal() {
        const modal = await modalController
            .create({
              component: NotificationsModal,
            })
        return modal.present();
      },
      showCategory(category){
          console.log("Category: " + category.uuid + " selected.");
      },
      renewAuth() {

        if (localStorage.getItem('refreshToken') !== null){
            console.log("Renewing Auth");
            // renew token
            axios.post(process.env.VUE_APP_API_URL + '/token/refresh', {
              'refresh_token': localStorage.refreshToken
            })
          .then(result => {
                localStorage.refreshToken = result.data.refresh_token;
                localStorage.userToken = result.data.token;
          });
        }

      },
      logInCheck() {
        // check if user is logged in or not
        if (localStorage.userId && localStorage.userName && localStorage.userToken){

          this.renewAuth();

          this.userIsLoggedIn = true;
          this.userName = localStorage.userName;

          this.appPages.push(
              {
                title: 'Deine Tickets',
                url: '/profile/entries',
                iosIcon: ticketOutline,
                mdIcon: ticketSharp
              }
          );

          this.appPages.push(
              {
                title: 'Profil',
                url: '/profile',
                iosIcon: personCircleOutline,
                mdIcon: personCircleSharp
              }
          );

          this.appPages.push(
              {
                title: 'Ausloggen',
                url: '/logout',
                iosIcon: logInOutline,
                mdIcon: logInSharp
              }
          );

          if (!localStorage.userRoles.includes('ROLE_STAR')){
            this.appPages.push(
                {
                  title: 'Star Bewerbung',
                  url: '/star-application',
                  iosIcon: archiveOutline,
                  mdIcon: archiveSharp
                }
            );
          } else {
            this.userIsStar = true;

            this.appPages.unshift(
                {
                  title: 'Meine Inserate',
                  url: '/profile/inserats',
                  iosIcon: albumsOutline,
                  mdIcon: albumsSharp,
                }
            );

            this.appPages.unshift(
                {
                  title: 'Inserat erstellen',
                  url: '/inserat/create',
                  iosIcon: addCircleOutline,
                  mdIcon: addCircleSharp,
                }
            );


          }


        } else {
          this.userIsLoggedIn = false;
          this.appPages.push(
              {
                title: 'Einloggen',
                url: '/login',
                iosIcon: logInOutline,
                mdIcon: logInSharp
              }
          );
        }

        if ((localStorage.refreshDone === "true") && (localStorage.redirectedToAllInserats === "false")){
          // if user is logged in and refresh is done, push to all inserats
          localStorage.redirectedToAllInserats = true;

          // if user needs confirmation, route user to confirm site
          if (localStorage.userIsVerified === "false"){
            this.$router.push({ path: '/register/confirm'});
          } else {
            this.$router.push({ path: '/all'});
          }
        }
      }
  },
  mounted() {

    // check if user is logged in or not
    this.logInCheck();

    // get categories
    axios.get(process.env.VUE_APP_API_URL + '/categories').then(response => {
      this.categories = response.data["hydra:member"];
      this.totalCategories = response.data["hydra:totalItems"];

      this.categories.forEach(category => {
          category.url = "/all/category/" + category.uuid;
      });
    });


    if (localStorage.userId && localStorage.userToken){
      // check if user has new notifications
      console.log("Checking User Notifications");
      axios.get(process.env.VUE_APP_API_URL + '/check-user-notifications', {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      }).then(response => {
        if (response.data.status == true){
          this.openNotificationsModal();
        }
      });
    }

    window.addEventListener('user-state-changed', (event) => {

      // check if user is logged in or not
      if (localStorage.userId && localStorage.userName && localStorage.userToken ){

        this.userIsLoggedIn = true;
        this.userName = localStorage.userName;

        // force refresh
        if (!localStorage.refreshDone){
            localStorage.refreshDone = true;
            window.location.reload();
        }

      } else {
        console.log("No User Data Found.");
        this.userIsLoggedIn = false;
        this.userName = null;
        this.$router.go(0);
      }

    });


  },
  setup() {
    const selectedIndex = ref(2);
    const appPages = [
      {
        title: 'Alle Inserate',
        url: '/all',
        iosIcon: albumsOutline,
        mdIcon: albumsSharp
      },
      {
        title: 'Für den Guten Zweck',
        url: '/goodCause',
        iosIcon: handLeftOutline,
        mdIcon: handLeftSharp
      },
      {
        title: 'Stars',
        url: '/stars',
        iosIcon: starOutline,
        mdIcon: starSharp
      },
      {
        title: 'Zuletzt Verkauft',
        url: '/all/last-buyed',
        iosIcon: archiveOutline,
        mdIcon: archiveSharp
      }
    ];
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders', 'Notes', 'Work', 'Travel', 'Reminders'];
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }

    const userIsLoggedIn = false;
    const userName = null;
    const userIsStar = false;

    const route = useRoute();
    
    return { 
      selectedIndex,
      appPages,
      labels,
      userIsLoggedIn,
      userName,
      userIsStar,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline,
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      albumsOutline,
      albumsSharp,
      handLeftOutline,
      handLeftSharp,
      starOutline,
      starSharp,
      personCircleOutline,
      personCircleSharp,
      logInOutline,
      logInSharp,
      addCircleOutline,
      addCircleSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : ''
    }
  }
});
