<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="tertiary" @click="close()">Schließen</ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="container" v-if="!finishedLoading">
    <ion-spinner name="dots"></ion-spinner>
  </div>

  <ion-content class="ion-padding" v-if="finishedLoading && notifications">

    <ion-card v-for="notification in notifications" :key="notification.id">
      <ion-card-header>
        <ion-card-subtitle><ion-icon v-if="notification.isSeen === false" :ios="alertIconIos" :md="alertIconMd"></ion-icon> {{ notification.title }}</ion-card-subtitle>
        <small>{{ notification.description }}</small>
      </ion-card-header>
    </ion-card>

    <ion-button style="width: calc(100% - 32px); margin-left: 16px; margin-right: 16px;" @click="setNotificationsAsSeen">Alle als gelesen markieren</ion-button>

  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonIcon,
  modalController,
  IonButtons, IonButton,
  IonCard, IonCardHeader, IonCardSubtitle
} from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from "axios";
import { informationCircleOutline, informationCircleSharp } from 'ionicons/icons';

export default defineComponent({
  name: 'NotificationsModal',
  props: {
    title: { type: String, default: 'Neuigkeiten für dich' },
  },
  data() {
    return {
      notifications: [],
      finishedLoading: false,
      alertIconIos: informationCircleOutline,
      alertIconMd: informationCircleSharp
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonSpinner, IonIcon, IonButtons, IonButton, IonCard, IonCardHeader, IonCardSubtitle },
  created() {

    this.finishedLoading = false;
    // get unread notifications
    axios.get(process.env.VUE_APP_API_URL + '/users/' + localStorage.userId + "/notifications?isSeen=false", {
      headers: {
        'Authorization': `Bearer ` + localStorage.userToken,
      }
    }).then(result => {
      this.notifications = result.data['hydra:member'];
      this.finishedLoading = true;
    });

  },
  methods: {
    async close() {
      await modalController.dismiss();
    },
    setNotificationsAsSeen() {

      this.notifications.forEach(notification => {
          const data = {"isSeen": true};
          axios.patch(process.env.VUE_APP_API_URL + '/notifications/' + notification.id, data, {
            headers: {
              'Content-Type' : 'application/merge-patch+json',
            }
          }).then(() => {
            notification.isSeen = true;
          });
      });

      this.close();

    }
  }
});
</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

</style>